<template lang="">
  <div>
    <div
      v-if="isDiscountApplied"
      class="rounded-lg py-3 mx-1 my-4 text-white font-style"
      :style="`background-color: ${getColor};`"
    >
      <h2 class="font-semibold text-xl ml-5 mt-3">
        {{ getNotesHeadline }}
      </h2>
      <div
        class="cost-list-for-notes items-start no-border"
        v-if="getNotesList.length > 0"
      >
        <div class="flex-grow">
          <div class="notes-wrapper">
            <ul class="list-disc pl-5">
              <li
                v-for="(note, noteIndex) in getNotesList"
                :key="`note-${noteIndex}`"
              >
                {{ note }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="py-3 no-border bg-gray-200 rounded-md mx-5"
        style="min-height: 55px"
        v-if="data.notes && data.notes.length > 0"
      >
        <div class="flex flex-column items-center justify-center">
          <div class="notes-wrapper">
            <ul class="list-disc pl-5">
              <li
                v-for="(note, noteIndex) in data.notes"
                :key="`note-${noteIndex}`"
              >
                {{ note }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TripNotesView',
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    getNotesHeadline() {
      return this.data.notes[0]
    },
    getNotesList() {
      let otherNotes = this.data.notes.filter((item, index) => index !== 0)
      return otherNotes
    },
    getColor() {
      if (this.data.pass_applied) {
        return `#f07300`
      }
      if (this.data.promo_applied) {
        return `#8200ff`
      }
      if (this.data.voucher_applied) {
        return `#0fb400`
      }
      return `#ffffff`
    },
    isDiscountApplied() {
      if (
        this.data.pass_applied ||
        this.data.promo_applied ||
        this.data.voucher_applied
      ) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>
<style lang="scss">
.cost-list-for-notes {
  display: flex;
  min-height: 55px;
  padding: 10px 20px;
}

.font-style {
  font-family: 'Roboto';
}
</style>
